.avatarWrapper {
    position: relative;
    width: 240px;
    height: 240px;
    border-radius: 100%;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    &:hover .overlay {
        opacity: 1;
    }
}

.avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.3s ease-in-out;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.uploadButton {
    transition: all 0.2s ease-in-out;

    &:hover {
        transform: scale(1.05);
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    }
}

.container {
    margin-bottom: 24px;
}