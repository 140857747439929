.table tr:last-child td:first-child {
  border-bottom: 0;
}

.table tr:last-child td:last-child {
  border-bottom: 0;
}

.table th {
  background-color: var(--chakra-colors-background400);
}

