.line {
  height: 1px;
  background-color: var(--chakra-colors-border200);
  width: 100%;
}

.container {
  align-items: center;
}

.text {
  color: var(--chakra-colors-border200);
  padding: 0 16px;
  text-transform: uppercase;
  font-size: 14px;
}