.backToTodayButtonContainer {
  width: 100%;
  position: fixed;
  bottom: 0;
  align-items: center;
  justify-content: center;
  padding: 24px 0;
  transform: translateY(150px);
  transition: all 0.5s ease-out;
}

.backToTodayButtonContainer.active {
  transform: translateY(0);
  transition: all 0.5s cubic-bezier(0.5, 0.4, 0.66, 1.76);
}

.aiCardContainer {
  margin-top: 24px;
}