.icon {
  margin-right: 32px;
}

.navbarItem {
  border: 1px solid transparent;
  font-weight: 500;
  transition: all 200ms;
  position: relative;
  padding: 8px 12px;
  border-radius: 4px;
}

.navbarItemBorder.active {
  width: 100%;
}

.navbarItemBorder {
  width: 0%;
  margin-bottom: -7px;
  border-radius: 16px;
  height: 2px;
  background-color: var(--chakra-colors-yellow300);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.navbarItem:hover {
  border: 1px solid var(--chakra-colors-yellow200);
  color: var(--chakra-colors-yellow500);
}

.navbarItem.active {
  background-color: var(--chakra-colors-yellow200);
  transition: all 200ms;
}

@media (max-width: 768px) {
  .icon {
    display: none;
  }
}