.bar {
  height: 64px;
  justify-content: space-evenly;
  position: relative;
}

.caret {
  width: 32px;
  height: 32px;
}

.barContainer {
  width: 100%;
  background-color: var(--chakra-colors-background300);
  border-bottom: 1px solid var(--chakra-colors-border100);
}

.arrow {
  padding: 0 12px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: all 0.1s ease-out;
}

.arrow:hover {
  transition: all 0.1s ease-out;
  background-color: var(--chakra-colors-background400);
}

.arrow:first-child {
  margin-right: 12px;
}

.arrow:last-child {
  margin-left: 12px;
}

.date {
  margin-top: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
  border: 2px solid transparent;
  opacity: 0.8;
  transition: all 0.1s ease-out;
}

.date:hover {
  opacity: 1;
  transition: all 0.1s ease-out;
}

.date.today {
  // border: 2px solid var(--chakra-colors-background400);
}

.date.selected {
  // background-color: #FFA819;
  border: 2px solid transparent;
  // color: #fff;
}

.dateContainer {
  background-color: transparent;
  transition: all 0.1s ease-out;
}

.dateContainer:hover {
  transition: all 0.1s ease-out;
  background-color: var(--chakra-colors-background400);
}

.indicator {
  left: 0;
  right: 0;
  position: absolute;
  height: 0;
  border-radius: 2px 2px 0 0;
  background-color: transparent;
  bottom: 0;
  transition: all 0.1s ease-out;
}

.indicator.selected {
  height: 2px;
  transition: all 0.1s ease-out;
  background-color: orange;
  background-image: linear-gradient(90deg, orange 0, #ffcb67 20%, orange 40%, orange);
  background-size: 99% 100%;
  background-repeat: no-repeat;
}

.indicator.active {
  animation: shimmer 1s linear 1ms infinite backwards;
}

.container {
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.snapshot {
  height: 40px;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 14px;
  border-top: 1px solid var(--chakra-colors-border100);
}

.day {
  font-size: 12px;
  opacity: 0.5;
  font-weight: 500;
}

.day.today {
  color: var(--chakra-colors-yellow400);
  opacity: 1;
}

.time {
  font-size: 16px;
  font-weight: 600;
  align-items: center;
}

@keyframes shimmer {
  0% {
    background-position: 100% * 5 100%;
  }

  100% {
    background-position: 100% * 100 100%;
  }
}

@keyframes blink {

  from,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-moz-keyframes blink {

  from,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {

  from,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-ms-keyframes blink {

  from,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-o-keyframes blink {

  from,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@media (max-width: 600px) {
  .bar {
    height: 56px;
  }

  .day {
    font-weight: 500;
    font-size: 11px;
  }

  .time {
    margin-top: 0px;
    font-size: 14px;
    font-weight: 600;
    align-items: center;
  }

  .caret {
    width: 52px;
    height: 52px;
  }

  .arrow {
    margin: 12px 16px;
    padding: 16px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: all 0.1s ease-out;
    border-radius: 100%;
  }

  .arrow.left {
    position: fixed;
    left: 0;
    bottom: 0;
  }

  .arrow.right {
    position: fixed;
    right: 0;
    bottom: 0;
  }

  .arrow:first-child {
    margin-right: 0;
  }

  .arrow:last-child {
    margin-left: 0;
  }
}