.card {
  background-color: var(--chakra-colors-background100);
  border-radius: 4px;
  border: 1px solid var(--chakra-colors-border100);
  padding: 24px;
}

.cardHeader {
  justify-content: space-between;
  padding: 0 0 18px 0;
  border-bottom: 1px solid var(--chakra-colors-border100);
  margin-bottom: 12px;
}

.cardHeader.standalone {
  justify-content: space-between;
  padding: 0 0 0 0;
  border-bottom: none;
  margin-bottom: 0;
}

.header {
  align-items: center;
  display: flex;
  font-weight: 500;
  font-size: 18px;
  flex-direction: "row";
  font-family: "General Sans", sans-serif;
}

.subtitle {
  align-items: center;
  display: flex;
  font-weight: 400;
  font-size: 14px;
  flex-direction: "row";
  opacity: 0.6;
}

.dataLabel {
  align-items: center;
  font-weight: 600;
  font-size: 48px;
}

.dataSubLabel {
  font-weight: 400;
  font-size: 32px;
}
