.listItem {
  flex-direction: row;
  width: 100%;
  padding: 16px;
  border-radius: 0;
  background-color: var(--chakra-colors-background100);
  border-bottom: 1px solid var(--chakra-colors-border100);
}

.header {
  font-size: 14px;
  border-bottom: 1px solid var(--chakra-colors-border100);
}

.listItem:hover {
  background-color: var(--chakra-colors-background200);
  transition: all 0.1s;
}

.listItem.enabled:active {
  background-color: var(--chakra-colors-background300);
  transition: all 0.2s;
}

.listItem.disabled {
  opacity: 0.4;
  pointer-events: none;
}

.projectName {
  font-size: 12px;
  opacity: 0.4;
}

.categoryName {
  font-size: 16px;
}

.description {
  font-size: 12px;
  opacity: 0.4;
}

.color {
  font-size: 14px;
  margin-top: 8px;
  opacity: 0.7;
}

.content {
  width: 100%;
  flex-direction: column;
}

.content.enabled {
  cursor: pointer;
}

.indicator {
  margin-right: 16px;
  margin-top: 4px;
  height: 36px;
  width: 36px;
  border-radius: 300px;
}

.container {
  justify-content: space-between;
  width: 100%;
  padding: 0 8px 0 24px;
}

.container.noIcon {
  padding: 0 8px 0 0;
}