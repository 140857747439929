.title {
  font-size: clamp(32px, 2.5vw, 42px);
  font-weight: 600;
}

.title.small {
  font-size: 28px;
}

.subtitle {
  opacity: 0.8;
}