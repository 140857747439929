.listItem {
  flex-direction: row;
  border-bottom: 1px solid var(--chakra-colors-border100);
  width: 100%;
  padding: 16px;
  border-radius: 0;
  background-color: var(--chakra-colors-background100);
}

.listItem:hover {
  background-color: var(--chakra-colors-background200);
  transition: all 0.1s;
}

.listItem:active {
  background-color: var(--chakra-colors-background300);
  transition: all 0.2s;
}

.projectName {
  font-size: 12px;
  opacity: 0.4;
}

.categoryName {
  font-size: 16px;
}

.description {
  font-size: 12px;
  opacity: 0.4;
}

.color {
  font-size: 14px;
  margin-top: 8px;
  opacity: 0.7;
}

.content {
  width: 100%;
  cursor: pointer;
  flex-direction: column;
}

.indicator {
  margin-right: 16px;
  height: 36px;
  width: 36px;
  border-radius: 10px;
}

.container {
  justify-content: space-between;
  width: 100%;
}
