.timer {
  cursor: pointer;
  align-items: flex-start;
}

.controls {
  align-items: center;
}

.time {
  font-weight: 500;
  margin-right: 6px;
  font-size: 18px;
  user-select: none;
}
