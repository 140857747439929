.navbar {
  background-color: var(--chakra-colors-background100);
  width: 100%;
  align-items: center;
  border-bottom: 1px solid var(--chakra-colors-border100);
}

.navbar.floating {
  background-color: var(--chakra-colors-background100);
  position: sticky;
  top: 0;
  border-bottom: 1px solid var(--chakra-colors-border100);
  z-index: 10;
}

.navbar.unauthenticated {
  background-color: transparent;
  border-bottom: 0;
  position: absolute;
}

.primaryNavItems {
  padding: 16px 0;
  align-items: center;
  justify-content: space-between;
}

.icon {
  height: 28px;
  margin-right: 32px;
}
